<template>
  <div>
    <van-cell>
      <template slot="title">
        <span style="color: #57c4b7"
          >*温馨提示：Pacs接口不支持影像传输，如需获取影像报告单，请到检查窗口问询</span
        >
      </template>
    </van-cell>
    <van-cell-group style="margin: 2%">
      <van-cell title="报告名称">
        <template slot="default">
          <span style="color: #57c4b7">{{ list.reporttitle_name }}</span>
        </template>
      </van-cell>
      <van-cell title="报告编码">
        <template slot="default">
          <span style="color: #57c4b7">{{ list.rq_seq }}</span>
        </template>
      </van-cell>
      <van-cell title="患者姓名">
        <template slot="default">
          <span style="color: #57c4b7">{{ list.name }}</span>
        </template>
      </van-cell>
      <van-cell title="检查医生姓名">
        <template slot="default">
          <span style="color: #57c4b7">{{ list.report_person }}</span>
        </template>
      </van-cell>
      <van-cell title="检查时间">
        <template slot="default">
          <span style="color: #57c4b7">{{ list.report_date }}</span>
        </template>
      </van-cell>
      <van-cell title="检查项目">
        <template slot="default">
          <span style="color: #57c4b7">{{ list.fl_name }}</span>
        </template>
      </van-cell>
      <van-cell title="检查部位">
        <template slot="default">
          <span style="color: #57c4b7">{{ list.check_part }}</span>
        </template>
      </van-cell>
      <van-cell title="确认医生姓名">
        <template slot="default">
          <span style="color: #57c4b7">{{ list.per_name }}</span>
        </template>
      </van-cell>
      <!-- <van-cell title="确认时间">
        <template slot="default">
          <span style="color: #57c4b7">{{ list.confirmTime }}</span>
        </template>
      </van-cell> -->
      <van-cell title="门诊号">
        <template slot="default">
          <span style="color: #57c4b7">{{ list.seq_no }}</span>
        </template>
      </van-cell>
      <van-cell title="报告内容">
        <template slot="label">
          <span style="color: #57c4b7">{{ list.result_text1 }}</span>
        </template>
      </van-cell>
      
      <!-- <van-cell title="挂号流水号">
        <template slot="default">
          <span style="color: #57c4b7">{{ list.regNo }}</span>
        </template>
      </van-cell> -->
      <van-cell title="报告诊断">
        <template slot="label">
          <span style="color: #57c4b7">{{ list.check_analyse }}</span>
        </template>
      </van-cell>

      <!-- <van-cell title="诊断结果">
        <template slot="label">
          <span style="color: #57c4b7">{{ list.result_text1 }}</span>
        </template>
      </van-cell> -->
      <!-- <van-cell title="报告类型" v-if="list.visitType === '0'">
        <template slot="default">
          <span style="color: #57c4b7">门诊</span>
        </template>
      </van-cell>
      <van-cell title="报告类型" v-else>
        <template slot="default">
          <span style="color: #57c4b7">住院</span>
        </template>
      </van-cell>
      <van-cell title="住院号">
        <template slot="default">
          <span style="color: #57c4b7">{{ list.inPatientId }}</span>
        </template>
      </van-cell>
      <van-cell title="住院流水号">
        <template slot="default">
          <span style="color: #57c4b7">{{ list.inpatientSeriNo }}</span>
        </template>
      </van-cell> -->
    </van-cell-group>
    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
      "
    >
      <span>@拓实银医</span>
    </div>
  </div>
</template>
<script>
import store from "../../store";
import ajax from "../../lib/ajax";
import { Toast } from "vant";
export default {
  data() {
    return {
      list: {},
    };
  },
  created() {
    this.onload();
  },
  methods: {
    async onload() {
      let RepId = store.getters["store/reportId"];
      let start =window.sessionStorage.getItem("starttime");
      let end =window.sessionStorage.getItem("endtime");
      let idcard = store.getters["store/idcard"];
      // alert(RepId);
      await ajax
        .post("/Api/ReportService/GetPacsReports", {
          idCard: idcard,
          begin: start+" 00:00:00",
          end: end+" 23:59:59",
          useQueryDate: true,
          machineName: "wechat",
        })
        .then((res) => {
          // let pacs = {};
         let pacs = res.data.result.reportInfos;
          for (let i = 0; i < pacs.length; i++) {
            // let idcard = store.getters["store/idcard"];
            if (pacs[i].rq_seq === RepId) {
              this.list = pacs[i];
            }
          }
          this.list.report_date = this.list.report_date.substring(0,10);
          console.log(this.list);
        })
        .catch((err) => {
          console.log(err.message);
          Toast("获取报告信息失败，请重试");
        });
    },
  },
};
</script>

<style>
</style>
